<template>
  <ml-container>
    <ml-grid>
      <div class="tw-col-span-5">
        <form ref="form" novalidate="false" @submit.prevent="dataEdit" @change="validateForm()" method="POST">

          <div class="tw-flex tw-items-center tw-justify-between">
            <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-4">
             {{ titleText }}
            </h1>
            <router-link :to="{ name: 'vendors.showcaseWork' }" class="tw-text-red tw-text-sm tw-flex tw-items-center tw-mb-4">
              <svg class="tw-mr-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1L1 5L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{$t('general.back')}}
            </router-link>
          </div>

          <div>

            <div class="tw-mt-8">
              <ml-label required for="title">{{$t('vendor.showcasework.title')}}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="item.title" id="title" name="title" required/>
                <ml-error-msg :msg="errors['title']" />
              </div>
            </div>

            <div class="tw-mt-6">
              <ml-label required for="description">{{$t('vendor.showcasework.description')}}</ml-label>
              <div class="tw-mt-2">
                <!--
                <ml-textarea v-model="item.description" id="description" name="description" required/>
                -->
                <editor
                    id="description" name="description"
                    v-model="item.description"
                    :init="{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help'
                    }"
                />
                <ml-error-msg :msg="errors['description']" />

              </div>
            </div>

            <div class="tw-mt-6">
              <ml-label>Photos</ml-label>
              <gallery v-if="item.images && item.images.length > 0"
                       class="tw-mt-3"
                       :images="item.images"
                       :gallery-setup="{}"
                       :default-image-class="'tw-h-28'"
                       :thumb-only="true"
                       :limit="item.images.length"
                       :thumb-wrapper-class="'tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-3'"
                       :show-border="false"
              >
                <template v-slot:image-action="{image}">
                  <a href="#" @click.prevent="destroyImage(image.id)"
                     class="tw-right-2 tw-top-2 tw-h-6 tw-w-6 tw-bg-white tw-rounded-full tw-absolute tw-text-black tw-flex tw-items-center tw-justify-center">
                    <x-icon class="tw-h-3 tw-w-3" />
                  </a>
                </template>
              </gallery>
            </div>

            <div class="tw-mt-6">
              <div v-if="item.id">
<!--                <v-carousel height="300" v-if="item.images.length">
                  <div v-for="(item,i) in item.images" :key="i">
                    <v-carousel-item
                        :src="item.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                      <span @click="destroyImage(item.id)"
                            class="btn py-1 px-2 bg-danger text-white position-absolute"
                            style="top: 0; right: 0">
                        <i class="fa fa-trash-alt"></i>
                      </span>
                    </v-carousel-item>
                  </div>

                </v-carousel>-->
           
                  <v-file-input
                      @change="onFileSelected"
                      accept="image/png, image/jpeg, image/bmp"
                      :placeholder="$t('vendor.pick_image')"
                      prepend-icon="mdi-camera"
                      :label="$t('vendor.image_file_size')"
                      v-model="file"
                  ></v-file-input>

            
                  <v-progress-linear
                      v-show="imageUpload"
                      indeterminate
                      color="red"
                      class="mb-0"
                  ></v-progress-linear>
                      <v-alert
                        v-if="imageUploadError"
                        color="orange"
                        outlined
                        shaped
                        type="info"
                        >
                        <p>{{imageUploadError.message}}</p>

                        <p v-for="(e,index) in imageUploadError.errors.image" :key="index">
                          {{e}}
                        </p>

                      </v-alert>
              </div>
            </div>
            <div class="tw-mt-8">
              <youtube-icon size="1.5x" class="custom-class"></youtube-icon>
              <ml-label for="youtube">{{$t('vendor.showcasework.youtube')}}</ml-label>
              <div class="tw-mt-2">
                
                <ml-input-text  placeholder="YouTube" v-model="item.youtube" id="youtube" name="youtube"/>
                <ml-error-msg :msg="errors['youtube']" />
              </div>
            </div>
            <div class="tw-mt-8">
              <video-icon size="1.5x" class="tw-mr-2"></video-icon>
              <ml-label for="vimeo">{{$t('vendor.showcasework.vimeo')}}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text  placeholder="Vimeo" v-model="item.vimeo" id="vimeo" name="vimeo"/>
                <ml-error-msg :msg="errors['vimeo']" />
              </div>
            </div>

          </div>

          <div class="tw-mt-6">
            <router-link :to="{ name: 'vendors.showcaseWork' }"
                         class="tw-mt-6 tw-mr-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              Cancel
            </router-link>
            <button type="submit"
                    :disabled="!valid || imageUpload || loading"
                    class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
              {{ buttonText }}
            </button>
          </div>
        </form>
      </div>
    </ml-grid>
  </ml-container>
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
//import MlTextarea from "@/components/layout/form/MlTextarea";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import i18n from '@/i18n'
import Gallery from "@/components/Gallery";
import { XIcon, YoutubeIcon, VideoIcon   } from "vue-feather-icons";

export default {
  components: {MlInputText, MlErrorMsg, MlLabel, MlGrid, MlContainer, Gallery, XIcon, YoutubeIcon, VideoIcon, Editor },
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  metaInfo() {
    return {
      title: i18n.t('profile'),
    }
  },
  data() {
    return {
      item: {},
      imageUploadError: null,
      imageUpload:false,
      selectedFile: null,
      loading: false,
      file: null,
      rules: {
        required: value => !!value || 'Required.'
      },
      valid: true,
      lazy: true,
      errors: []
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getItem();
    }
  },
  computed: {
    buttonText() {
      return this.item.id ? 'Save' : 'Create'
    },
    titleText() {
      return this.$route.params.id ? i18n.t('vendor.showcasework.edit_showcasework') : i18n.t('vendor.showcasework.create_showcasework')
    }
  },
  methods: {
    validateYoutube(v){
      var re = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return re.test(v);
    },
    validateVimeo(v){
      var re = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
      return re.test(v);
    },      
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

        if (!this.item.title) {
          label = "Title required.";
          this.errors.push(label);
          this.errors["title"] = label;
        }

        if (!this.item.description) {
          label = "Description required.";
          this.errors.push(label);
          this.errors["description"] = label;
        }

        console.log("youtube",this.validateYoutube(this.item.youtube));

        if (this.item.youtube && !this.validateYoutube(this.item.youtube)) {
          label = "Invalid format for Youtube link";
          this.errors.push(label);
          this.errors["youtube"] = label;
        }        

        if (this.item.vimeo && !this.validateVimeo(this.item.vimeo)) {
          label = "Invalid format for Vimeo link";
          this.errors.push(label);
          this.errors["vimeo"] = label;
        }     


      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },    
    dataEdit() {

      if(!this.validateForm()){
        console.log("Not valid", this.errors);
        return;
      }
      if (this.item.id) {
        this.updateItem();
      } else {
        this.addItem();
      }
    },
    async addItem() {
     // await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;
      // Disable add showcase work
      return;
      /*
      this.loading = true;
      let uri = '/api/vendor/showcaseworks';
      await this.$http.post(uri, this.item)
          .then((response) => {
            this.item = response.data.data
            this.$toast.success(response.data.message, "Success")
          });
      this.loading = false;
      */          
    },
    async updateItem() {
      //await this.$refs.form.validate();
      if(!this.validateForm()){
        return;
      }
      if (!this.valid) return;

      this.loading = true;
      let uri = '/api/vendor/showcaseworks/' + this.item.id;
      await this.$http.patch(uri, this.item)
          .then((response) => {
            console.log(response.data);
            this.$toast.success(response.data.message, "Success");
            this.$router.go(-1)
          });
      this.loading = false;

    },
    getItem() {
      this.loading = true;
      let uri = '/api/vendor/showcaseworks/' + this.$route.params.id + '/edit';
      this.$http.get(uri).then((response) => {
        this.item = response.data.data;
        this.loading = false
      });
    },
    onFileSelected(event) {
      console.log(event);
      if (event == null) return;
      //this.selectedFile = event.target.files[0];
      this.selectedFile = event;
      this.onUpload();
    },
    onUpload() {
      this.imageUpload = true;
      this.imageUploadError = null;
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      let uri = '/api/vendor/showcaseworks/images/' + this.item.id;
      this.$http.post(uri, formData, {
        onUploadProgress: uploadEvent => {
          console.log('Upload progress: ' + Math.round(uploadEvent.loaded / uploadEvent.total * 100));
        }
      }).then((response) => {
        this.item.images = response.data.images;
        //this.item.images = this.item.images.concat(response.data.images);
        this.$toast.success(response.data.message, "Success")
        //this.$refs.inputFile.reset();
        this.file = null;
        this.imageUpload = false;
      }).catch((error) => {
        this.imageUpload = false;
        this.imageUploadError = error.data;
        console.log('Error: ', error.data);
      });
    },
    destroyImage(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.onImageDelete(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    onImageDelete(id) {
      console.log(id);
      let uri = '/api/vendor/showcaseworks/images/' + this.item.id;
      console.log("del:" + uri);

      this.$http.delete(uri, {data: {media_id: id}})
          .then((response) => {
            //this.item.images.push(response.data.images),
            this.item.images = response.data.images;
            //this.item.images = response.data.images;
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          });
    },
  }
}
</script>
